/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from ".";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getTableCount = /* GraphQL */ `query GetTableCount($key: String!) {
  getTableCount(key: $key)
}
` as GeneratedQuery<
  APITypes.GetTableCountQueryVariables,
  APITypes.GetTableCountQuery
>;
export const getSystemGlobalSecrets = /* GraphQL */ `query GetSystemGlobalSecrets($id: ID!) {
  getSystemGlobalSecrets(id: $id) {
    id
    config
    record
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSystemGlobalSecretsQueryVariables,
  APITypes.GetSystemGlobalSecretsQuery
>;
export const listSystemGlobalSecrets = /* GraphQL */ `query ListSystemGlobalSecrets(
  $id: ID
  $filter: ModelSystemGlobalSecretsFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listSystemGlobalSecrets(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      config
      record
      updatedAt
      createdAt
      updatedBy
      createdBy
      key
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSystemGlobalSecretsQueryVariables,
  APITypes.ListSystemGlobalSecretsQuery
>;
export const getSurveyNotifyReportEmail = /* GraphQL */ `query GetSurveyNotifyReportEmail($id: ID!) {
  getSurveyNotifyReportEmail(id: $id) {
    id
    email
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSurveyNotifyReportEmailQueryVariables,
  APITypes.GetSurveyNotifyReportEmailQuery
>;
export const listSurveyNotifyReportEmails = /* GraphQL */ `query ListSurveyNotifyReportEmails(
  $id: ID
  $filter: ModelSurveyNotifyReportEmailFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listSurveyNotifyReportEmails(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      email
      updatedAt
      createdAt
      updatedBy
      createdBy
      key
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSurveyNotifyReportEmailsQueryVariables,
  APITypes.ListSurveyNotifyReportEmailsQuery
>;
export const getSurveyDownloadReportEmail = /* GraphQL */ `query GetSurveyDownloadReportEmail($id: ID!) {
  getSurveyDownloadReportEmail(id: $id) {
    id
    reportID
    reportYear
    email
    updatedAt
    createdAt
    allowedContact
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSurveyDownloadReportEmailQueryVariables,
  APITypes.GetSurveyDownloadReportEmailQuery
>;
export const listSurveyDownloadReportEmails = /* GraphQL */ `query ListSurveyDownloadReportEmails(
  $id: ID
  $filter: ModelSurveyDownloadReportEmailFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listSurveyDownloadReportEmails(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      reportID
      reportYear
      email
      updatedAt
      createdAt
      allowedContact
      updatedBy
      createdBy
      key
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSurveyDownloadReportEmailsQueryVariables,
  APITypes.ListSurveyDownloadReportEmailsQuery
>;
export const getSystemInternalConfigurationVariablesReadonly = /* GraphQL */ `query GetSystemInternalConfigurationVariablesReadonly($id: ID!) {
  getSystemInternalConfigurationVariablesReadonly(id: $id) {
    id
    variable_name
    variable_value
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSystemInternalConfigurationVariablesReadonlyQueryVariables,
  APITypes.GetSystemInternalConfigurationVariablesReadonlyQuery
>;
export const listSystemInternalConfigurationVariablesReadonlies = /* GraphQL */ `query ListSystemInternalConfigurationVariablesReadonlies(
  $id: ID
  $filter: ModelSystemInternalConfigurationVariablesReadonlyFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listSystemInternalConfigurationVariablesReadonlies(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      variable_name
      variable_value
      updatedAt
      createdAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSystemInternalConfigurationVariablesReadonliesQueryVariables,
  APITypes.ListSystemInternalConfigurationVariablesReadonliesQuery
>;
export const getSystemProcesses = /* GraphQL */ `query GetSystemProcesses($id: ID!) {
  getSystemProcesses(id: $id) {
    id
    process_name
    process_status
    percentage_complete
    started_by
    exceptions
    updatedAt
    createdAt
    key
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSystemProcessesQueryVariables,
  APITypes.GetSystemProcessesQuery
>;
export const listSystemProcesses = /* GraphQL */ `query ListSystemProcesses(
  $id: ID
  $filter: ModelSystemProcessesFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listSystemProcesses(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      process_name
      process_status
      percentage_complete
      started_by
      exceptions
      updatedAt
      createdAt
      key
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSystemProcessesQueryVariables,
  APITypes.ListSystemProcessesQuery
>;
export const getTableCSVs = /* GraphQL */ `query GetTableCSVs($id: ID!) {
  getTableCSVs(id: $id) {
    id
    table_name
    s3Key
    s3Name
    s3Type
    requested_by
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTableCSVsQueryVariables,
  APITypes.GetTableCSVsQuery
>;
export const listTableCSVs = /* GraphQL */ `query ListTableCSVs(
  $id: ID
  $filter: ModelTableCSVsFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listTableCSVs(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      table_name
      s3Key
      s3Name
      s3Type
      requested_by
      updatedAt
      createdAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTableCSVsQueryVariables,
  APITypes.ListTableCSVsQuery
>;
export const getSortedSystemGlobalSecrets = /* GraphQL */ `query GetSortedSystemGlobalSecrets(
  $key: String!
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSystemGlobalSecretsFilterInput
  $limit: Int
  $nextToken: String
) {
  getSortedSystemGlobalSecrets(
    key: $key
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      config
      record
      updatedAt
      createdAt
      updatedBy
      createdBy
      key
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSortedSystemGlobalSecretsQueryVariables,
  APITypes.GetSortedSystemGlobalSecretsQuery
>;
export const getSortedSurveyNotifyReportEmail = /* GraphQL */ `query GetSortedSurveyNotifyReportEmail(
  $key: String!
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSurveyNotifyReportEmailFilterInput
  $limit: Int
  $nextToken: String
) {
  getSortedSurveyNotifyReportEmail(
    key: $key
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      email
      updatedAt
      createdAt
      updatedBy
      createdBy
      key
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSortedSurveyNotifyReportEmailQueryVariables,
  APITypes.GetSortedSurveyNotifyReportEmailQuery
>;
export const surveyDownloadReportEmailsByEmail = /* GraphQL */ `query SurveyDownloadReportEmailsByEmail(
  $email: String!
  $sortDirection: ModelSortDirection
  $filter: ModelSurveyDownloadReportEmailFilterInput
  $limit: Int
  $nextToken: String
) {
  surveyDownloadReportEmailsByEmail(
    email: $email
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      reportID
      reportYear
      email
      updatedAt
      createdAt
      allowedContact
      updatedBy
      createdBy
      key
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SurveyDownloadReportEmailsByEmailQueryVariables,
  APITypes.SurveyDownloadReportEmailsByEmailQuery
>;
export const getSortedSurveyDownloadReportEmail = /* GraphQL */ `query GetSortedSurveyDownloadReportEmail(
  $key: String!
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSurveyDownloadReportEmailFilterInput
  $limit: Int
  $nextToken: String
) {
  getSortedSurveyDownloadReportEmail(
    key: $key
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      reportID
      reportYear
      email
      updatedAt
      createdAt
      allowedContact
      updatedBy
      createdBy
      key
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSortedSurveyDownloadReportEmailQueryVariables,
  APITypes.GetSortedSurveyDownloadReportEmailQuery
>;
export const getSystemInternalConfigurationVariablesReadonlyByVariableName = /* GraphQL */ `query GetSystemInternalConfigurationVariablesReadonlyByVariableName(
  $variable_name: String!
  $sortDirection: ModelSortDirection
  $filter: ModelSystemInternalConfigurationVariablesReadonlyFilterInput
  $limit: Int
  $nextToken: String
) {
  getSystemInternalConfigurationVariablesReadonlyByVariableName(
    variable_name: $variable_name
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      variable_name
      variable_value
      updatedAt
      createdAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSystemInternalConfigurationVariablesReadonlyByVariableNameQueryVariables,
  APITypes.GetSystemInternalConfigurationVariablesReadonlyByVariableNameQuery
>;
export const getSortedSystemProcesses = /* GraphQL */ `query GetSortedSystemProcesses(
  $key: String!
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSystemProcessesFilterInput
  $limit: Int
  $nextToken: String
) {
  getSortedSystemProcesses(
    key: $key
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      process_name
      process_status
      percentage_complete
      started_by
      exceptions
      updatedAt
      createdAt
      key
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSortedSystemProcessesQueryVariables,
  APITypes.GetSortedSystemProcessesQuery
>;
export const getByTableName = /* GraphQL */ `query GetByTableName(
  $table_name: String!
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTableCSVsFilterInput
  $limit: Int
  $nextToken: String
) {
  getByTableName(
    table_name: $table_name
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      table_name
      s3Key
      s3Name
      s3Type
      requested_by
      updatedAt
      createdAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetByTableNameQueryVariables,
  APITypes.GetByTableNameQuery
>;
export const getCMSPages = /* GraphQL */ `query GetCMSPages($id: ID!) {
  getCMSPages(id: $id) {
    id
    pageTitle
    metaDescription
    pageURL
    content
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCMSPagesQueryVariables,
  APITypes.GetCMSPagesQuery
>;
export const listCMSPages = /* GraphQL */ `query ListCMSPages(
  $id: ID
  $filter: ModelCMSPagesFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCMSPages(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      pageTitle
      metaDescription
      pageURL
      content
      updatedAt
      createdAt
      updatedBy
      createdBy
      key
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCMSPagesQueryVariables,
  APITypes.ListCMSPagesQuery
>;
export const getByTitleCMSPages = /* GraphQL */ `query GetByTitleCMSPages(
  $pageTitle: String!
  $sortDirection: ModelSortDirection
  $filter: ModelCMSPagesFilterInput
  $limit: Int
  $nextToken: String
) {
  getByTitleCMSPages(
    pageTitle: $pageTitle
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      pageTitle
      metaDescription
      pageURL
      content
      updatedAt
      createdAt
      updatedBy
      createdBy
      key
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetByTitleCMSPagesQueryVariables,
  APITypes.GetByTitleCMSPagesQuery
>;
export const getByURLCMSPages = /* GraphQL */ `query GetByURLCMSPages(
  $pageURL: String!
  $sortDirection: ModelSortDirection
  $filter: ModelCMSPagesFilterInput
  $limit: Int
  $nextToken: String
) {
  getByURLCMSPages(
    pageURL: $pageURL
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      pageTitle
      metaDescription
      pageURL
      content
      updatedAt
      createdAt
      updatedBy
      createdBy
      key
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetByURLCMSPagesQueryVariables,
  APITypes.GetByURLCMSPagesQuery
>;
export const getSortedCMSPages = /* GraphQL */ `query GetSortedCMSPages(
  $key: String!
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCMSPagesFilterInput
  $limit: Int
  $nextToken: String
) {
  getSortedCMSPages(
    key: $key
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      pageTitle
      metaDescription
      pageURL
      content
      updatedAt
      createdAt
      updatedBy
      createdBy
      key
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSortedCMSPagesQueryVariables,
  APITypes.GetSortedCMSPagesQuery
>;
export const getCMSMenus = /* GraphQL */ `query GetCMSMenus($id: ID!) {
  getCMSMenus(id: $id) {
    id
    menu
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCMSMenusQueryVariables,
  APITypes.GetCMSMenusQuery
>;
export const listCMSMenus = /* GraphQL */ `query ListCMSMenus(
  $id: ID
  $filter: ModelCMSMenusFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCMSMenus(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      menu
      updatedAt
      createdAt
      updatedBy
      createdBy
      key
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCMSMenusQueryVariables,
  APITypes.ListCMSMenusQuery
>;
export const getByMenuCMSPages = /* GraphQL */ `query GetByMenuCMSPages(
  $menu: String!
  $sortDirection: ModelSortDirection
  $filter: ModelCMSMenusFilterInput
  $limit: Int
  $nextToken: String
) {
  getByMenuCMSPages(
    menu: $menu
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      menu
      updatedAt
      createdAt
      updatedBy
      createdBy
      key
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetByMenuCMSPagesQueryVariables,
  APITypes.GetByMenuCMSPagesQuery
>;
export const getSortedCMSMenus = /* GraphQL */ `query GetSortedCMSMenus(
  $key: String!
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCMSMenusFilterInput
  $limit: Int
  $nextToken: String
) {
  getSortedCMSMenus(
    key: $key
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      menu
      updatedAt
      createdAt
      updatedBy
      createdBy
      key
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSortedCMSMenusQueryVariables,
  APITypes.GetSortedCMSMenusQuery
>;
export const getCMSMenuItems = /* GraphQL */ `query GetCMSMenuItems($id: ID!) {
  getCMSMenuItems(id: $id) {
    id
    menuID
    menuItem
    type
    link
    componentID
    sortNumber
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCMSMenuItemsQueryVariables,
  APITypes.GetCMSMenuItemsQuery
>;
export const listCMSMenuItems = /* GraphQL */ `query ListCMSMenuItems(
  $id: ID
  $filter: ModelCMSMenuItemsFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCMSMenuItems(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      menuID
      menuItem
      type
      link
      componentID
      sortNumber
      updatedAt
      createdAt
      updatedBy
      createdBy
      key
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCMSMenuItemsQueryVariables,
  APITypes.ListCMSMenuItemsQuery
>;
export const getByMenuIDForCMSMenuItem = /* GraphQL */ `query GetByMenuIDForCMSMenuItem(
  $menuID: ID!
  $sortNumber: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCMSMenuItemsFilterInput
  $limit: Int
  $nextToken: String
) {
  getByMenuIDForCMSMenuItem(
    menuID: $menuID
    sortNumber: $sortNumber
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      menuID
      menuItem
      type
      link
      componentID
      sortNumber
      updatedAt
      createdAt
      updatedBy
      createdBy
      key
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetByMenuIDForCMSMenuItemQueryVariables,
  APITypes.GetByMenuIDForCMSMenuItemQuery
>;
export const getCMSMetas = /* GraphQL */ `query GetCMSMetas($id: ID!) {
  getCMSMetas(id: $id) {
    id
    name
    contents
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCMSMetasQueryVariables,
  APITypes.GetCMSMetasQuery
>;
export const listCMSMetas = /* GraphQL */ `query ListCMSMetas(
  $id: ID
  $filter: ModelCMSMetasFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCMSMetas(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      name
      contents
      updatedAt
      createdAt
      updatedBy
      createdBy
      key
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCMSMetasQueryVariables,
  APITypes.ListCMSMetasQuery
>;
export const getSortedCMSMetas = /* GraphQL */ `query GetSortedCMSMetas(
  $key: String!
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCMSMetasFilterInput
  $limit: Int
  $nextToken: String
) {
  getSortedCMSMetas(
    key: $key
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      contents
      updatedAt
      createdAt
      updatedBy
      createdBy
      key
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSortedCMSMetasQueryVariables,
  APITypes.GetSortedCMSMetasQuery
>;
export const getCMSHeadScripts = /* GraphQL */ `query GetCMSHeadScripts($id: ID!) {
  getCMSHeadScripts(id: $id) {
    id
    name
    script
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCMSHeadScriptsQueryVariables,
  APITypes.GetCMSHeadScriptsQuery
>;
export const listCMSHeadScripts = /* GraphQL */ `query ListCMSHeadScripts(
  $id: ID
  $filter: ModelCMSHeadScriptsFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCMSHeadScripts(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      name
      script
      updatedAt
      createdAt
      updatedBy
      createdBy
      key
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCMSHeadScriptsQueryVariables,
  APITypes.ListCMSHeadScriptsQuery
>;
export const getSortedCMSHeadScripts = /* GraphQL */ `query GetSortedCMSHeadScripts(
  $key: String!
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCMSHeadScriptsFilterInput
  $limit: Int
  $nextToken: String
) {
  getSortedCMSHeadScripts(
    key: $key
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      script
      updatedAt
      createdAt
      updatedBy
      createdBy
      key
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSortedCMSHeadScriptsQueryVariables,
  APITypes.GetSortedCMSHeadScriptsQuery
>;
export const getCMSConfigurations = /* GraphQL */ `query GetCMSConfigurations($id: ID!) {
  getCMSConfigurations(id: $id) {
    id
    config
    record
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCMSConfigurationsQueryVariables,
  APITypes.GetCMSConfigurationsQuery
>;
export const listCMSConfigurations = /* GraphQL */ `query ListCMSConfigurations(
  $id: ID
  $filter: ModelCMSConfigurationsFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCMSConfigurations(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      config
      record
      updatedAt
      createdAt
      updatedBy
      createdBy
      key
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCMSConfigurationsQueryVariables,
  APITypes.ListCMSConfigurationsQuery
>;
export const getSortedCMSConfigurations = /* GraphQL */ `query GetSortedCMSConfigurations(
  $key: String!
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCMSConfigurationsFilterInput
  $limit: Int
  $nextToken: String
) {
  getSortedCMSConfigurations(
    key: $key
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      config
      record
      updatedAt
      createdAt
      updatedBy
      createdBy
      key
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSortedCMSConfigurationsQueryVariables,
  APITypes.GetSortedCMSConfigurationsQuery
>;
export const getSurveySettings = /* GraphQL */ `query GetSurveySettings($id: ID!) {
  getSurveySettings(id: $id) {
    id
    name
    setting
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSurveySettingsQueryVariables,
  APITypes.GetSurveySettingsQuery
>;
export const listSurveySettings = /* GraphQL */ `query ListSurveySettings(
  $id: ID
  $filter: ModelSurveySettingsFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listSurveySettings(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      name
      setting
      updatedAt
      createdAt
      updatedBy
      createdBy
      key
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSurveySettingsQueryVariables,
  APITypes.ListSurveySettingsQuery
>;
export const getSortedSurveySettings = /* GraphQL */ `query GetSortedSurveySettings(
  $key: String!
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSurveySettingsFilterInput
  $limit: Int
  $nextToken: String
) {
  getSortedSurveySettings(
    key: $key
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      setting
      updatedAt
      createdAt
      updatedBy
      createdBy
      key
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSortedSurveySettingsQueryVariables,
  APITypes.GetSortedSurveySettingsQuery
>;
export const getSurveyReports = /* GraphQL */ `query GetSurveyReports($id: ID!) {
  getSurveyReports(id: $id) {
    id
    reportStatus
    year
    description
    reportImage
    s3Key
    s3Name
    s3Type
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSurveyReportsQueryVariables,
  APITypes.GetSurveyReportsQuery
>;
export const listSurveyReports = /* GraphQL */ `query ListSurveyReports(
  $id: ID
  $filter: ModelSurveyReportsFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listSurveyReports(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      reportStatus
      year
      description
      reportImage
      s3Key
      s3Name
      s3Type
      updatedAt
      createdAt
      updatedBy
      createdBy
      key
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSurveyReportsQueryVariables,
  APITypes.ListSurveyReportsQuery
>;
export const getSortedSurveyReports = /* GraphQL */ `query GetSortedSurveyReports(
  $key: String!
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSurveyReportsFilterInput
  $limit: Int
  $nextToken: String
) {
  getSortedSurveyReports(
    key: $key
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      reportStatus
      year
      description
      reportImage
      s3Key
      s3Name
      s3Type
      updatedAt
      createdAt
      updatedBy
      createdBy
      key
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSortedSurveyReportsQueryVariables,
  APITypes.GetSortedSurveyReportsQuery
>;
