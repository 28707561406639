import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import type DownloadEmailDialogProps from './DownloadEmailDialogProps';
import SurveyDownloadReportEmailAPI from '@internal/apis/SurveyDownloadReportEmailAPI';
import { Form } from '@internal/form';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Storage } from 'aws-amplify';
import CircularProgress from '@mui/material/CircularProgress';
import { createPublicSurveyDownloadReportEmail } from '@internal/apis/amplify/graphql/mutations';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from 'react-google-recaptcha-v3';
import GAEvents from '@internal/utils/GAEvents';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const DownloadEmailDialog: React.FC<DownloadEmailDialogProps> = ({
                                                                   open,
                                                                   logo,
                                                                   report,
                                                                   close,
                                                                   googleKey,
                                                                 }) => {
  const [loading, setLoading] = React.useState(false);
  const linkRef = React.useRef<any>(null);
  const dataForm = React.useRef<any>();
  const [refreshReCaptcha, setRefreshReCaptcha] = React.useState(true);
  const [allowedContact, setAllowedContact] = React.useState('yes');
  const formConfig = [
    {
      name: 'email',
      label: 'Email',
      fieldType: 'input',
      isRequired: true,
      validations: {
        type: 'input',
        required: true,
        customMessages: {
          required: "Email can't be blank.",
        },
      },
    },
  ];

  const [objectURL, setObjectURL] = React.useState<any>(null);
  const anchorRef = React.useRef<any>(null);

  const submitForm = async (data: any) => {
    setRefreshReCaptcha(true);
    setLoading(true);
    dataForm.current = data;
  };

  const processForm = async (token: any) => {
    const data = dataForm.current;
    data.reportID = report.id;
    data.reportYear = report.year;
    data.token = token;
    data.key = 'Survey_Download_Report_Email';
    data.createdAt = new Date().toISOString();
    data.updatedAt = new Date().toISOString();
    data.allowedContact = allowedContact; // Set the value of allowedContact

    const response = await SurveyDownloadReportEmailAPI.custom_call(
        null,
        createPublicSurveyDownloadReportEmail,
        { args: data },
        'createPublicSurveyDownloadReportEmail',
    );
    GAEvents.logReportDownload(report.year);
    if (response === 'Ok' && report.reportStatus === 'Active') {
      await fetchPDF();
    }
    setLoading(false);
    setRefreshReCaptcha(false);
  };

  const fetchPDF = async () => {
    try {
      const signedUrl = await Storage.get(report.s3Key ?? '', {
        level: 'public',
      });
      const response = await fetch(signedUrl);
      let base64data = await response.text();
      const mimeType = 'data:application/pdf;base64,';
      if (base64data.startsWith(mimeType)) {
        base64data = base64data.substring(mimeType.length);
      }

      const byteCharacters = atob(base64data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      const objectURL = URL.createObjectURL(blob);
      window.location.href = objectURL;
    } catch (error) {
      console.error('Error fetching the s3 PDF', error);
    }
  };

  React.useEffect(() => {
    if (objectURL) {
      anchorRef.current.click();
      close();
    }
  }, [objectURL]);

  return (
      <Dialog
          open={open}
          onClose={close}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth
          style={{ overflow: 'hidden' }}>
        <a
            href={objectURL ?? '#'}
            ref={anchorRef}
            style={{ display: 'none' }}
            target="_blank"
            rel="noopener noreferrer">
          Hidden Link
        </a>

        <DialogTitle
            id="alert-dialog-title"
            style={{
              backgroundColor: '#367fbb',
              padding: '1.25rem',
              textAlign: 'center',
            }}>
          {logo && (
              <img src={logo} alt="" width="220" style={{ textAlign: 'center' }} />
          )}
          <IconButton
              edge="end"
              color="inherit"
              onClick={() => {
                close();
              }}
              aria-label="close"
              style={{ position: 'absolute', right: '16px', top: '8px' }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <GoogleReCaptchaProvider reCaptchaKey={googleKey}>
            <div style={{ padding: '2.5rem' }}>
              <p
                  style={{
                    color: '#44546a',
                    lineHeight: '1.56',
                    fontStyle: 'italic',
                    margin: '0 0 1.25rem',
                  }}>
                We will contact you with future Startup Muster reports and surveys
              </p>
              {loading ? (
                  <>
                    <GoogleReCaptcha
                        onVerify={async (token: string) => {
                          await processForm(token);
                        }}
                        refreshReCaptcha={refreshReCaptcha}
                    />
                    <p
                        style={{
                          margin: '0 0 1.25rem',
                          color: '#44546a',
                          lineHeight: '1.56',
                          fontSize: '20px',
                          fontWeight: 'bold',
                        }}>
                      Thank you! Your download should start shortly.
                    </p>
                  </>
              ) : (
                  <>
                    <p
                        style={{
                          margin: '0 0 1.25rem',
                          color: '#44546a',
                          lineHeight: '1.56',
                          fontSize: '20px',
                          fontWeight: '600',
                        }}>
                      Enter your email address to download the free report
                    </p>

                    <FormControlLabel
                        control={
                          <Checkbox
                              checked={allowedContact === 'yes'}
                              onChange={(e) =>
                                  setAllowedContact(e.target.checked ? 'yes' : 'no')
                              }
                          />
                        }
                        label="Allow contact from the organisations that support Startup Muster’s work"
                    />
                    <Form
                        fields={formConfig as any}
                        onSubmit={submitForm}
                        submitButtonText={'Download Report'}
                    />
                  </>
              )}
            </div>
          </GoogleReCaptchaProvider>
        </DialogContent>
      </Dialog>
  );
};

export default DownloadEmailDialog;
