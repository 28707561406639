/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from ".";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createCMSPages = /* GraphQL */ `mutation CreateCMSPages(
  $input: CreateCMSPagesInput!
  $condition: ModelCMSPagesConditionInput
) {
  createCMSPages(input: $input, condition: $condition) {
    id
    pageTitle
    metaDescription
    pageURL
    content
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCMSPagesMutationVariables,
  APITypes.CreateCMSPagesMutation
>;
export const updateCMSPages = /* GraphQL */ `mutation UpdateCMSPages(
  $input: UpdateCMSPagesInput!
  $condition: ModelCMSPagesConditionInput
) {
  updateCMSPages(input: $input, condition: $condition) {
    id
    pageTitle
    metaDescription
    pageURL
    content
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCMSPagesMutationVariables,
  APITypes.UpdateCMSPagesMutation
>;
export const deleteCMSPages = /* GraphQL */ `mutation DeleteCMSPages(
  $input: DeleteCMSPagesInput!
  $condition: ModelCMSPagesConditionInput
) {
  deleteCMSPages(input: $input, condition: $condition) {
    id
    pageTitle
    metaDescription
    pageURL
    content
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCMSPagesMutationVariables,
  APITypes.DeleteCMSPagesMutation
>;
export const createCMSMenus = /* GraphQL */ `mutation CreateCMSMenus(
  $input: CreateCMSMenusInput!
  $condition: ModelCMSMenusConditionInput
) {
  createCMSMenus(input: $input, condition: $condition) {
    id
    menu
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCMSMenusMutationVariables,
  APITypes.CreateCMSMenusMutation
>;
export const updateCMSMenus = /* GraphQL */ `mutation UpdateCMSMenus(
  $input: UpdateCMSMenusInput!
  $condition: ModelCMSMenusConditionInput
) {
  updateCMSMenus(input: $input, condition: $condition) {
    id
    menu
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCMSMenusMutationVariables,
  APITypes.UpdateCMSMenusMutation
>;
export const deleteCMSMenus = /* GraphQL */ `mutation DeleteCMSMenus(
  $input: DeleteCMSMenusInput!
  $condition: ModelCMSMenusConditionInput
) {
  deleteCMSMenus(input: $input, condition: $condition) {
    id
    menu
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCMSMenusMutationVariables,
  APITypes.DeleteCMSMenusMutation
>;
export const createCMSMenuItems = /* GraphQL */ `mutation CreateCMSMenuItems(
  $input: CreateCMSMenuItemsInput!
  $condition: ModelCMSMenuItemsConditionInput
) {
  createCMSMenuItems(input: $input, condition: $condition) {
    id
    menuID
    menuItem
    type
    link
    componentID
    sortNumber
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCMSMenuItemsMutationVariables,
  APITypes.CreateCMSMenuItemsMutation
>;
export const updateCMSMenuItems = /* GraphQL */ `mutation UpdateCMSMenuItems(
  $input: UpdateCMSMenuItemsInput!
  $condition: ModelCMSMenuItemsConditionInput
) {
  updateCMSMenuItems(input: $input, condition: $condition) {
    id
    menuID
    menuItem
    type
    link
    componentID
    sortNumber
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCMSMenuItemsMutationVariables,
  APITypes.UpdateCMSMenuItemsMutation
>;
export const deleteCMSMenuItems = /* GraphQL */ `mutation DeleteCMSMenuItems(
  $input: DeleteCMSMenuItemsInput!
  $condition: ModelCMSMenuItemsConditionInput
) {
  deleteCMSMenuItems(input: $input, condition: $condition) {
    id
    menuID
    menuItem
    type
    link
    componentID
    sortNumber
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCMSMenuItemsMutationVariables,
  APITypes.DeleteCMSMenuItemsMutation
>;
export const createCMSMetas = /* GraphQL */ `mutation CreateCMSMetas(
  $input: CreateCMSMetasInput!
  $condition: ModelCMSMetasConditionInput
) {
  createCMSMetas(input: $input, condition: $condition) {
    id
    name
    contents
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCMSMetasMutationVariables,
  APITypes.CreateCMSMetasMutation
>;
export const updateCMSMetas = /* GraphQL */ `mutation UpdateCMSMetas(
  $input: UpdateCMSMetasInput!
  $condition: ModelCMSMetasConditionInput
) {
  updateCMSMetas(input: $input, condition: $condition) {
    id
    name
    contents
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCMSMetasMutationVariables,
  APITypes.UpdateCMSMetasMutation
>;
export const deleteCMSMetas = /* GraphQL */ `mutation DeleteCMSMetas(
  $input: DeleteCMSMetasInput!
  $condition: ModelCMSMetasConditionInput
) {
  deleteCMSMetas(input: $input, condition: $condition) {
    id
    name
    contents
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCMSMetasMutationVariables,
  APITypes.DeleteCMSMetasMutation
>;
export const createCMSHeadScripts = /* GraphQL */ `mutation CreateCMSHeadScripts(
  $input: CreateCMSHeadScriptsInput!
  $condition: ModelCMSHeadScriptsConditionInput
) {
  createCMSHeadScripts(input: $input, condition: $condition) {
    id
    name
    script
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCMSHeadScriptsMutationVariables,
  APITypes.CreateCMSHeadScriptsMutation
>;
export const updateCMSHeadScripts = /* GraphQL */ `mutation UpdateCMSHeadScripts(
  $input: UpdateCMSHeadScriptsInput!
  $condition: ModelCMSHeadScriptsConditionInput
) {
  updateCMSHeadScripts(input: $input, condition: $condition) {
    id
    name
    script
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCMSHeadScriptsMutationVariables,
  APITypes.UpdateCMSHeadScriptsMutation
>;
export const deleteCMSHeadScripts = /* GraphQL */ `mutation DeleteCMSHeadScripts(
  $input: DeleteCMSHeadScriptsInput!
  $condition: ModelCMSHeadScriptsConditionInput
) {
  deleteCMSHeadScripts(input: $input, condition: $condition) {
    id
    name
    script
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCMSHeadScriptsMutationVariables,
  APITypes.DeleteCMSHeadScriptsMutation
>;
export const createCMSConfigurations = /* GraphQL */ `mutation CreateCMSConfigurations(
  $input: CreateCMSConfigurationsInput!
  $condition: ModelCMSConfigurationsConditionInput
) {
  createCMSConfigurations(input: $input, condition: $condition) {
    id
    config
    record
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCMSConfigurationsMutationVariables,
  APITypes.CreateCMSConfigurationsMutation
>;
export const updateCMSConfigurations = /* GraphQL */ `mutation UpdateCMSConfigurations(
  $input: UpdateCMSConfigurationsInput!
  $condition: ModelCMSConfigurationsConditionInput
) {
  updateCMSConfigurations(input: $input, condition: $condition) {
    id
    config
    record
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCMSConfigurationsMutationVariables,
  APITypes.UpdateCMSConfigurationsMutation
>;
export const deleteCMSConfigurations = /* GraphQL */ `mutation DeleteCMSConfigurations(
  $input: DeleteCMSConfigurationsInput!
  $condition: ModelCMSConfigurationsConditionInput
) {
  deleteCMSConfigurations(input: $input, condition: $condition) {
    id
    config
    record
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCMSConfigurationsMutationVariables,
  APITypes.DeleteCMSConfigurationsMutation
>;
export const createSystemGlobalSecrets = /* GraphQL */ `mutation CreateSystemGlobalSecrets(
  $input: CreateSystemGlobalSecretsInput!
  $condition: ModelSystemGlobalSecretsConditionInput
) {
  createSystemGlobalSecrets(input: $input, condition: $condition) {
    id
    config
    record
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSystemGlobalSecretsMutationVariables,
  APITypes.CreateSystemGlobalSecretsMutation
>;
export const updateSystemGlobalSecrets = /* GraphQL */ `mutation UpdateSystemGlobalSecrets(
  $input: UpdateSystemGlobalSecretsInput!
  $condition: ModelSystemGlobalSecretsConditionInput
) {
  updateSystemGlobalSecrets(input: $input, condition: $condition) {
    id
    config
    record
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSystemGlobalSecretsMutationVariables,
  APITypes.UpdateSystemGlobalSecretsMutation
>;
export const deleteSystemGlobalSecrets = /* GraphQL */ `mutation DeleteSystemGlobalSecrets(
  $input: DeleteSystemGlobalSecretsInput!
  $condition: ModelSystemGlobalSecretsConditionInput
) {
  deleteSystemGlobalSecrets(input: $input, condition: $condition) {
    id
    config
    record
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSystemGlobalSecretsMutationVariables,
  APITypes.DeleteSystemGlobalSecretsMutation
>;
export const createSurveySettings = /* GraphQL */ `mutation CreateSurveySettings(
  $input: CreateSurveySettingsInput!
  $condition: ModelSurveySettingsConditionInput
) {
  createSurveySettings(input: $input, condition: $condition) {
    id
    name
    setting
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSurveySettingsMutationVariables,
  APITypes.CreateSurveySettingsMutation
>;
export const updateSurveySettings = /* GraphQL */ `mutation UpdateSurveySettings(
  $input: UpdateSurveySettingsInput!
  $condition: ModelSurveySettingsConditionInput
) {
  updateSurveySettings(input: $input, condition: $condition) {
    id
    name
    setting
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSurveySettingsMutationVariables,
  APITypes.UpdateSurveySettingsMutation
>;
export const deleteSurveySettings = /* GraphQL */ `mutation DeleteSurveySettings(
  $input: DeleteSurveySettingsInput!
  $condition: ModelSurveySettingsConditionInput
) {
  deleteSurveySettings(input: $input, condition: $condition) {
    id
    name
    setting
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSurveySettingsMutationVariables,
  APITypes.DeleteSurveySettingsMutation
>;
export const createSurveyReports = /* GraphQL */ `mutation CreateSurveyReports(
  $input: CreateSurveyReportsInput!
  $condition: ModelSurveyReportsConditionInput
) {
  createSurveyReports(input: $input, condition: $condition) {
    id
    reportStatus
    year
    description
    reportImage
    s3Key
    s3Name
    s3Type
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSurveyReportsMutationVariables,
  APITypes.CreateSurveyReportsMutation
>;
export const updateSurveyReports = /* GraphQL */ `mutation UpdateSurveyReports(
  $input: UpdateSurveyReportsInput!
  $condition: ModelSurveyReportsConditionInput
) {
  updateSurveyReports(input: $input, condition: $condition) {
    id
    reportStatus
    year
    description
    reportImage
    s3Key
    s3Name
    s3Type
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSurveyReportsMutationVariables,
  APITypes.UpdateSurveyReportsMutation
>;
export const deleteSurveyReports = /* GraphQL */ `mutation DeleteSurveyReports(
  $input: DeleteSurveyReportsInput!
  $condition: ModelSurveyReportsConditionInput
) {
  deleteSurveyReports(input: $input, condition: $condition) {
    id
    reportStatus
    year
    description
    reportImage
    s3Key
    s3Name
    s3Type
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSurveyReportsMutationVariables,
  APITypes.DeleteSurveyReportsMutation
>;
export const createSurveyNotifyReportEmail = /* GraphQL */ `mutation CreateSurveyNotifyReportEmail(
  $input: CreateSurveyNotifyReportEmailInput!
  $condition: ModelSurveyNotifyReportEmailConditionInput
) {
  createSurveyNotifyReportEmail(input: $input, condition: $condition) {
    id
    email
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSurveyNotifyReportEmailMutationVariables,
  APITypes.CreateSurveyNotifyReportEmailMutation
>;
export const updateSurveyNotifyReportEmail = /* GraphQL */ `mutation UpdateSurveyNotifyReportEmail(
  $input: UpdateSurveyNotifyReportEmailInput!
  $condition: ModelSurveyNotifyReportEmailConditionInput
) {
  updateSurveyNotifyReportEmail(input: $input, condition: $condition) {
    id
    email
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSurveyNotifyReportEmailMutationVariables,
  APITypes.UpdateSurveyNotifyReportEmailMutation
>;
export const deleteSurveyNotifyReportEmail = /* GraphQL */ `mutation DeleteSurveyNotifyReportEmail(
  $input: DeleteSurveyNotifyReportEmailInput!
  $condition: ModelSurveyNotifyReportEmailConditionInput
) {
  deleteSurveyNotifyReportEmail(input: $input, condition: $condition) {
    id
    email
    updatedAt
    createdAt
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSurveyNotifyReportEmailMutationVariables,
  APITypes.DeleteSurveyNotifyReportEmailMutation
>;
export const createSurveyDownloadReportEmail = /* GraphQL */ `mutation CreateSurveyDownloadReportEmail(
  $input: CreateSurveyDownloadReportEmailInput!
  $condition: ModelSurveyDownloadReportEmailConditionInput
) {
  createSurveyDownloadReportEmail(input: $input, condition: $condition) {
    id
    reportID
    reportYear
    email
    updatedAt
    createdAt
    allowedContact
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSurveyDownloadReportEmailMutationVariables,
  APITypes.CreateSurveyDownloadReportEmailMutation
>;
export const updateSurveyDownloadReportEmail = /* GraphQL */ `mutation UpdateSurveyDownloadReportEmail(
  $input: UpdateSurveyDownloadReportEmailInput!
  $condition: ModelSurveyDownloadReportEmailConditionInput
) {
  updateSurveyDownloadReportEmail(input: $input, condition: $condition) {
    id
    reportID
    reportYear
    email
    updatedAt
    createdAt
    allowedContact
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSurveyDownloadReportEmailMutationVariables,
  APITypes.UpdateSurveyDownloadReportEmailMutation
>;
export const deleteSurveyDownloadReportEmail = /* GraphQL */ `mutation DeleteSurveyDownloadReportEmail(
  $input: DeleteSurveyDownloadReportEmailInput!
  $condition: ModelSurveyDownloadReportEmailConditionInput
) {
  deleteSurveyDownloadReportEmail(input: $input, condition: $condition) {
    id
    reportID
    reportYear
    email
    updatedAt
    createdAt
    allowedContact
    updatedBy
    createdBy
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSurveyDownloadReportEmailMutationVariables,
  APITypes.DeleteSurveyDownloadReportEmailMutation
>;
export const createSystemInternalConfigurationVariablesReadonly = /* GraphQL */ `mutation CreateSystemInternalConfigurationVariablesReadonly(
  $input: CreateSystemInternalConfigurationVariablesReadonlyInput!
  $condition: ModelSystemInternalConfigurationVariablesReadonlyConditionInput
) {
  createSystemInternalConfigurationVariablesReadonly(
    input: $input
    condition: $condition
  ) {
    id
    variable_name
    variable_value
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSystemInternalConfigurationVariablesReadonlyMutationVariables,
  APITypes.CreateSystemInternalConfigurationVariablesReadonlyMutation
>;
export const updateSystemInternalConfigurationVariablesReadonly = /* GraphQL */ `mutation UpdateSystemInternalConfigurationVariablesReadonly(
  $input: UpdateSystemInternalConfigurationVariablesReadonlyInput!
  $condition: ModelSystemInternalConfigurationVariablesReadonlyConditionInput
) {
  updateSystemInternalConfigurationVariablesReadonly(
    input: $input
    condition: $condition
  ) {
    id
    variable_name
    variable_value
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSystemInternalConfigurationVariablesReadonlyMutationVariables,
  APITypes.UpdateSystemInternalConfigurationVariablesReadonlyMutation
>;
export const deleteSystemInternalConfigurationVariablesReadonly = /* GraphQL */ `mutation DeleteSystemInternalConfigurationVariablesReadonly(
  $input: DeleteSystemInternalConfigurationVariablesReadonlyInput!
  $condition: ModelSystemInternalConfigurationVariablesReadonlyConditionInput
) {
  deleteSystemInternalConfigurationVariablesReadonly(
    input: $input
    condition: $condition
  ) {
    id
    variable_name
    variable_value
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSystemInternalConfigurationVariablesReadonlyMutationVariables,
  APITypes.DeleteSystemInternalConfigurationVariablesReadonlyMutation
>;
export const createSystemProcesses = /* GraphQL */ `mutation CreateSystemProcesses(
  $input: CreateSystemProcessesInput!
  $condition: ModelSystemProcessesConditionInput
) {
  createSystemProcesses(input: $input, condition: $condition) {
    id
    process_name
    process_status
    percentage_complete
    started_by
    exceptions
    updatedAt
    createdAt
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSystemProcessesMutationVariables,
  APITypes.CreateSystemProcessesMutation
>;
export const updateSystemProcesses = /* GraphQL */ `mutation UpdateSystemProcesses(
  $input: UpdateSystemProcessesInput!
  $condition: ModelSystemProcessesConditionInput
) {
  updateSystemProcesses(input: $input, condition: $condition) {
    id
    process_name
    process_status
    percentage_complete
    started_by
    exceptions
    updatedAt
    createdAt
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSystemProcessesMutationVariables,
  APITypes.UpdateSystemProcessesMutation
>;
export const deleteSystemProcesses = /* GraphQL */ `mutation DeleteSystemProcesses(
  $input: DeleteSystemProcessesInput!
  $condition: ModelSystemProcessesConditionInput
) {
  deleteSystemProcesses(input: $input, condition: $condition) {
    id
    process_name
    process_status
    percentage_complete
    started_by
    exceptions
    updatedAt
    createdAt
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSystemProcessesMutationVariables,
  APITypes.DeleteSystemProcessesMutation
>;
export const createTableCSVs = /* GraphQL */ `mutation CreateTableCSVs(
  $input: CreateTableCSVsInput!
  $condition: ModelTableCSVsConditionInput
) {
  createTableCSVs(input: $input, condition: $condition) {
    id
    table_name
    s3Key
    s3Name
    s3Type
    requested_by
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTableCSVsMutationVariables,
  APITypes.CreateTableCSVsMutation
>;
export const updateTableCSVs = /* GraphQL */ `mutation UpdateTableCSVs(
  $input: UpdateTableCSVsInput!
  $condition: ModelTableCSVsConditionInput
) {
  updateTableCSVs(input: $input, condition: $condition) {
    id
    table_name
    s3Key
    s3Name
    s3Type
    requested_by
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTableCSVsMutationVariables,
  APITypes.UpdateTableCSVsMutation
>;
export const deleteTableCSVs = /* GraphQL */ `mutation DeleteTableCSVs(
  $input: DeleteTableCSVsInput!
  $condition: ModelTableCSVsConditionInput
) {
  deleteTableCSVs(input: $input, condition: $condition) {
    id
    table_name
    s3Key
    s3Name
    s3Type
    requested_by
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTableCSVsMutationVariables,
  APITypes.DeleteTableCSVsMutation
>;
export const createPublicSurveyDownloadReportEmail = /* GraphQL */ `mutation CreatePublicSurveyDownloadReportEmail(
  $args: InputPublicSurveyDownloadReportEmail!
) {
  createPublicSurveyDownloadReportEmail(args: $args)
}
` as GeneratedMutation<
  APITypes.CreatePublicSurveyDownloadReportEmailMutationVariables,
  APITypes.CreatePublicSurveyDownloadReportEmailMutation
>;
export const createPublicSurveyNotifyReportEmail = /* GraphQL */ `mutation CreatePublicSurveyNotifyReportEmail(
  $args: InputPublicSurveyNotifyReportEmail!
) {
  createPublicSurveyNotifyReportEmail(args: $args)
}
` as GeneratedMutation<
  APITypes.CreatePublicSurveyNotifyReportEmailMutationVariables,
  APITypes.CreatePublicSurveyNotifyReportEmailMutation
>;
